import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import BlockContent from "@sanity/block-content-to-react"

import Class from "../../types/Class"
import Layout from "../../components/layout"
import SpellsSection from "./components/spells"

interface DataProps {
  data: {
    class: Class
  }
}

const ClassPage: React.FC<DataProps> = ({ data }) => {
  const BlockRenderer = props => {
    const { style } = props.node
    if (/^h\d/.test(style)) {
      const CustomTag = `${style}` as keyof JSX.IntrinsicElements
      const alignCenter =
        props?.node?.children[0]?.marks?.indexOf("center") >= 0
      const alignRight = props?.node?.children[0]?.marks?.indexOf("right") >= 0
      if (alignCenter || alignRight) {
        return (
          <div style={{ textAlign: alignCenter ? "center" : "right" }}>
            <CustomTag>{props.children}</CustomTag>
          </div>
        )
      }
      return (
        <div>
          <CustomTag>{props.children}</CustomTag>
        </div>
      )
    }

    const alignCenter = props?.node?.children[0]?.marks?.indexOf("center") >= 0
    const alignRight = props?.node?.children[0]?.marks?.indexOf("right") >= 0

    if (alignCenter || alignRight) {
      return (
        <p style={{ textAlign: alignCenter ? "center" : "right" }}>
          {props.children}
        </p>
      )
    }
    return BlockContent.defaultSerializers.types.block(props)
  }

  return (
    <Layout>
      <div className="bg-texture block">
        <div className="max-w-4xl mx-auto px-6 sm:px-0 bg-title classStyles">
          <div className="mt-12 titleStyle">
            <h1 className="text-mocha text-5xl font-display font-bold text-center">
              {data.class.name}
            </h1>
          </div>
          <div className="sm:h-112 sm:float-right classImage">
            <Image
              {...data.class.image}
              alt={`${data.class.name} Avatar`}
              style={{
                width: "100%",
                height: "100%",
                // objectFit: "cover",
              }}
            />
          </div>

          <div className="font-body text-fontSize body max-w-none mx-auto prose leading-relaxed font-medium">
            <BlockContent
              blocks={data.class._rawFlavorText}
              className="classContent"
              serializers={{ types: { block: BlockRenderer } }}
            />
          </div>

          <div className="mt-2">
            <h2 className="text-mocha text-4xl md:text-5xl font-display font-bold text-center">
              Class Spells
            </h2>
            <SpellsSection spells={data.class.spells} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ClassPage

export const query = graphql`
  query($id: String!) {
    class: sanityClass(id: { eq: $id }) {
      id
      name
      _rawFlavorText
      spells {
        name
        level
        type
        roll_cost
        mana_cost
        action
        range
        non_battle_mode
        duration
        handicaps
        requirements
        _rawEffect
        spell_flavor_text
      }
      image {
        ...ImageWithPreview
      }
    }
  }
`
